// consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2 NEW获取火车票改签订单详情
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：

const consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2 = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-od-trains-order-change/getTrainsChangeOrderDetailV2',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2;

