import TrainTimetable from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-timetable/1.0.0/index.vue'
import consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2 from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2'
import consumer_t_od_trains_order_change_changePolling from "@/lib/data-service/haolv-default/consumer_t_od_trains_order_change_changePolling";
import consumer_t_tp_refund_change_policy_get from "@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get";
import moment from "moment";
import {awaitWrap} from '@/page/admin/company-management/staff-management/common/unit'
import consumer_journey_orderFreeApprove from '@/lib/data-service/haolv-default/consumer_journey_orderFreeApprove'

export default {
  data() {
    return {
      orderFreeApprove: false,
      pageLoading: false,
      loading: false,
      orderNo: '',
      changeNo: '',
      //orderDetail: {},
      changeDetail: {},
      //oriPassengerList: [],

      id_setInterval: null,//轮询占座结果的定时器id
      occupiedStatus: 1,//占座整体结果状态码：1.占座处理中;2.占座部分成功;3.占座全部成功;4.占座全部失败
      occupiedStatusText: '',//占座整体结果状态描述,
      progressBarIntervalId: null,
      progressObj: {
        percentage: 0,
        tip: '正在确认车次信息...'
      },
    }
  },
  components: {TrainTimetable},
  created() {
  },
  mounted() {
  },
  activated() {
    this.orderNo = this.$route.query.orderNo;
    this.changeNo = this.$route.query.changeNo;
    consumer_journey_orderFreeApprove({businessType: 2, orderNo: this.orderNo}).then(res => {
      this.orderFreeApprove = res.datas.orderFreeApprove;
    });
    this.reset();
    this.getChangeDetail();
  },
  deactivated() {
    clearInterval(this.progressBarIntervalId);
    clearInterval(this.id_setInterval)
  },
  destroyed() {
  },
  watch: {},
  computed: {},
  filters: {
    filterDay(val) {
      if (!val) {
        return ''
      }
      let day = moment(val).format('MM月DD日');
      return day
    },
    filterWeek(val) {
      if (!val) {
        return "";
      }
      let day = moment(val).day();
      if (day === 0) {
        return "周日";
      } else if (day === 1) {
        return "周一";
      } else if (day === 2) {
        return "周二";
      } else if (day === 3) {
        return "周三";
      } else if (day === 4) {
        return "周四";
      } else if (day === 5) {
        return "周五";
      } else if (day === 6) {
        return "周六";
      } else {
        return "";
      }
    },
  },
  methods: {
    async getChangeDetail() {
      this.pageLoading = true;
      const getChangeDetail = consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2({changeNo: this.changeNo});
      let [err, res] = await awaitWrap(getChangeDetail)
      if (err) {
        this.pageLoading = false;
        return
      }
      this.pageLoading = false;
      this.changeDetail = res.datas;
      // 判断改签订单状态
      const changeStatus = this.changeDetail && this.changeDetail.changeOrderInfo ? this.changeDetail.changeOrderInfo.changeOrderStatus : '';
      // 如果不是占座中，则跳转
      if ([0, 1, 2, 7].indexOf(changeStatus) === -1) {
        // 跳转到订单详情
        this.$router.push({
          name: 'admin-my-order-train-change-detail',
          query: {
            orderNo: this.orderNo,
            changeNo: this.changeNo
          }
        })
        return
      }
      this.$nextTick(() => {
        const timeParams = {
          fromStationCode: this.changeDetail.changeTrainInfo.changeFromStationCode,
          toStationCode: this.changeDetail.changeTrainInfo.changeToStationCode,
          trainCode: this.changeDetail.changeTrainInfo.changeTrainCode,
          trainDate: this.changeDetail.changeTrainInfo.changeStartDate,
        };
        this.$refs.aTrainTimetable.init(timeParams)
      })
      this.progressBarAnimation();
      this.orderChangingPolling(this.changeNo);
    },

    //进度条动画效果
    progressBarAnimation() {
      let _this = this;
      this.loading = true;
      this.progressBarIntervalId = setInterval(function () {
        if (_this.occupiedStatus === 1) {
          _this.progressObj.percentage += 1;
        }
        if (_this.progressObj.percentage === 90) {
          clearInterval(_this.progressBarIntervalId);
        }
        if (_this.progressObj.percentage < 40) {
          _this.progressObj.tip = '正在确认车次信息...';
        } else if (_this.progressObj.percentage < 60) {
          _this.progressObj.tip = '正在提交订单信息...'
        } else if (_this.progressObj.percentage < 80) {
          _this.progressObj.tip = '正在核验乘车人信息...'
        } else {
          _this.progressObj.tip = '座席锁定中...'
        }
      }, 100)
    },

    //改签-轮询占座结果
    orderChangingPolling(changeNo) {
      let _this = this;

      _this.id_setInterval = setInterval(async function () {
        let res = null;

        res = await consumer_t_od_trains_order_change_changePolling({
          changeNo: _this.changeNo
        });

        const {
          status,
          statusText,
          havePriceChange
        } = res.datas;
        _this.occupiedStatus = status;
        _this.occupiedStatusText = statusText;

        // 占座结果全部完成,结束轮询
        if (_this.occupiedStatus !== 1) {
          clearInterval(_this.id_setInterval);
          clearInterval(_this.progressBarIntervalId);
        }

        //占座全部成功，3秒后：0.无需审批-跳转至改签申请提示页；2.需审批-跳转至改签申请提示页
        if (_this.occupiedStatus === 3) {
          let isNeedApproval = false;
          _this.loading = false;
          _this.progressObj.percentage = 100;
          _this.progressObj.tip = '占座成功'
          consumer_t_tp_refund_change_policy_get().then(res => {
            isNeedApproval = res.datas.changePolicyType === 2;
            setTimeout(() => {
              this.dialogVisible = false;
              if (isNeedApproval) {
                if (this.orderFreeApprove) {
                  _this.toChangeApplyResult();
                } else {
                  _this.toChangeResult();
                }
              } else {
                _this.toChangeResult();
              }
            }, 2000)
          })
        }

        //占座全部失败，3秒后跳转至火车票行程预定首页
        if (_this.occupiedStatus === 4) {
          setTimeout(() => {
            this.$alert(_this.occupiedStatusText, '', {
              confirmButtonText: '我知道了',
              callback: action => {
                _this.toTrainHome()
              }
            });
          }, 1000);
        }
      }.bind(this), 1000)
    },
    //返回火车票行程预定首页
    toTrainHome() {
      this.$router.replace({
        name: 'admin-my-order-train-change-detail',
        query: {
          orderNo: this.changeNo
        }
      })
    },
    //跳转至改签申请提示页
    toChangeApplyResult() {
      this.$router.replace({
        name: 'admin-train-change-apply-result',
        query: {
          changeNo: this.changeNo
        }
      });
    },

    //跳转至支付确认页面
    toChangeResult() {
      this.$router.replace({
        name: 'admin-train-change-submit-pay',
        query: {
          orderNo: this.orderNo,
          changeNo: this.changeNo
        }
      });
    },
    //重置数据
    reset() {
      this.progressObj = {
        percentage: 0,
        tip: '正在确认车次信息...'
      };
      this.occupiedStatus = 1;//占座整体结果状态码：1.占座处理中;2.占座部分成功;3.占座全部成功;4.占座全部失败
      this.occupiedStatusText = '';//占座整体结果状态描述
    },
  }
}
